//React
import React from 'react'

//Packages
import Layout from '../components/layout'

//Components
import SignalIcon from '../images/svg/404/noun_lost_2503787.svg'

const NotFoundPage = () => (
  <Layout>
    <hr size={1} class="border-top-1-gray-6" />
    <div class="container container-page container-page-demo-3">
      <div class="row d-flex flex-column justify-content-center align-items-center text-center">
        <div class="col-lg-8 d-flex flex-column align-items-center justify-content-center">
          {/* <h3>Oops!</h3> */}
          <div class="lost-icon p-4">
            <SignalIcon />
          </div>
          <p class="lead black-3">
            We couldn't find the page you were looking for.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
